import React, { Suspense, useEffect, useState, lazy } from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
const Resume_view = lazy(() => import('./components/Resume_jsonld/Resume_view'));
const Home = lazy(() => import('./pages/Home'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const RefundPolicy = lazy(() => import('./pages/RefundPolicy'));
const Mentor = lazy(() => import('./pages/Mentor'));
const Login = lazy(() => import('./components/loginregister/Login'));
const Register = lazy(() => import('./components/loginregister/Register'));
const Menubar = lazy(() => import('./containers/menubar/Menubar'));
const Mentee = lazy(() => import('./pages/Mentee'));
const Navbar = lazy(() => import('./components/navbar/Navbar'));
const Footer = lazy(() => import('./containers/footer/Footer'));
const MakeAppointment = lazy(() => import('./containers/scheduler/MakeAppointment'));
const ProtectedRoute = lazy(() => import('./ProtecteddRoute'));
const Corporate = lazy(() => import('./pages/Corporate'));
const ResumeLanding = lazy(() => import('./components/resume/ResumeLanding'));
const UserNotFound = lazy(() => import('./components/error-pages/UserNotFound'));
const PricePlan = lazy(() => import('./pages/PricePlan'));
const MenteeLanding = lazy(() => import('./pages/MenteeLanding'));
const MentorLanding = lazy(() => import('./pages/MentorLanding'));
const EnterpriseLanding = lazy(() => import('./pages/EnterpriseLanding'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const AdminDashboard = lazy(() => import('./components/admin/AdminDashboard'));
const ResumeMain = lazy(() => import('./components/Resume_jsonld/ResumeMain'));
const EmailActivate = lazy(() => import('./components/loginregister/EmailActivate'));
const ContactPage = lazy(() => import('./containers/contactus/ContactPage'));
const ResetPassword = lazy(() => import('./components/loginregister/ResetPassword'));
const AdminLogin = lazy(() => import('./components/loginregister/Adminlogin'));
const Reassign = lazy(() => import('./components/admin/Reassign'));
const HDFC_Gateway = lazy(() => import('./containers/payment/HDFC_Gateway'));
const SessionTimeout = lazy(() => import('./SessionTimeout'));
const matchresume = lazy(() => import('./components/admin/matchresume'));
const Dashboard = lazy(() => import('./components/corporate/Dashboard'))
const AssessmentsPage = lazy(() => import('./components/assessment/AssessmentsPage'))
const SocialAuth = lazy(() => import('./components/loginregister/SocialAuth'));
import secureLocalStorage from "react-secure-storage";
const ExpertMultiStepForm = lazy(() => import('./containers/multi-step-forms/ExpertMultiStepForm'));
const ResumeSharedDashboard = lazy(() => import('./components/corporate/ResumeSharedDashboard'));
const EditJD = lazy(() => import('./components/admin/EditJD'));
const MenteeRecieptPage = lazy(() => import('./tables/MenteeRecieptPage'))
const termsAndConditions = lazy(() => import('./pages/termsAndConditions'))

// import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

const App = (props) => {

  const isAuth = secureLocalStorage.getItem('token') ? true : false;

  const [userpayload, updateuserpayload] = useState()

  try {
    var token = secureLocalStorage.getItem('token')
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    var payload = JSON.parse(window.atob(base64));
  }
  catch { payload = { user_id: null } }
  const [profileType, setprofileType] = useState({
    Mentor: false,
    Mentee: false,
    Corporate: false,
    Admin: false
  })
  useEffect(() => {
    //Triggers when auth variable changes
    //Triggers get api of specific logged in user to get personalised details which will be updated to all other respective profile components 
    var requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'JWT' + ' ' + secureLocalStorage.getItem('token') }
    };
    if (payload.user_id != null) {
      AppSpinner.removeAttribute('hidden'); // Show loading spinner
    }
    else {
      AppSpinner.setAttribute('hidden', ''); // Hide loading spinner
    }
    try {
      fetch(process.env.REACT_APP_API + "/getUserData/" + payload.user_id, requestOptions)
        .then(response => response.json())
        .then(result => {
          AppSpinner.setAttribute('hidden', ''); // Hide loading spinner
          if (result.code == 'token_not_valid') {
            secureLocalStorage.removeItem('token');
            secureLocalStorage.clear();
            window.location.reload();
          }
          else {
            updateuserpayload(result)
            const profile = result[0].profile_level
            //depending on api response profile type will restrict/protect other routes.
            if (profile == 'Mentor') {
              setprofileType({
                Mentor: true,
                Mentee: false,
                Corporate: false,
                Admin: false
              })
            } else if (profile == 'Mentee') {
              setprofileType({
                Mentor: false,
                Mentee: true,
                Corporate: false,
                Admin: false
              })
            }
            else if (profile == 'Corporate') {
              setprofileType({
                Mentor: false,
                Mentee: false,
                Corporate: true,
                Admin: false
              })
            }
            else if (profile == 'KarmminAdmin') {
              setprofileType({
                Mentor: false,
                Mentee: false,
                Corporate: false,
                Admin: true
              })
            } else {
              setprofileType({
                Mentor: false,
                Mentee: false,
                Corporate: false,
                Admin: false
              })
            }
          }
        })
        .catch(error => {
          if (error instanceof TypeError) {
            if ((error.message == 'Failed to fetch' && payload.user_id != null)) {
              secureLocalStorage.removeItem('token');
              secureLocalStorage.clear();
              window.location.reload();
              AppSpinner.setAttribute('hidden', ''); // Hide loading spinner
            }
          }
        });
    } catch {
      setprofileType({
        Mentor: false,
        Mentee: false,
        Corporate: false,
        Admin: false
      })
    }
    // Update the document title using the browser API
  }, [isAuth]);
  const [scroll, setScroll] = useState(0);
  useEffect(() => {
    const progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const Scroll = `${totalScroll / windowHeight}`;
      setScroll(Scroll);
    }
    window.addEventListener("scroll", progressBarHandler);
    return () => window.removeEventListener("scroll", progressBarHandler);
  });
  //session handle based logout definition
  const handleClick = () => {
    secureLocalStorage.clear()
    window.location.href = '/'
  }

  return (
    <>
      <div hidden id='AppSpinner'></div>
      <div className="App">
        <Router>
          <Suspense fallback={<div id='spinner'></div>}>
            <Switch>
              <Route exact path={['/', '/privacy-policy', '/refund-policy', '/terms-and-conditions', '/edit-jd', '/candidate', '/Resumes-shared',
                '/Resume', '/membership-plans', '/welcome-candidate', '/welcome-expert', '/expert', '/schedule-an-appointment',
                '/welcome-enterprise', '/about-us', '/contact-us', '/payment', '/reassign', '/dashboard', '/enterprise', '/payment_page',
                '/hdfcpayment', '/membership-plans', '/welcome-candidate', '/welcome-expert', '/karmminadmin', '/matchresume', '/view/:id/:jdid', '/conditions']}>
                <div id="progressBarContainer">
                  <div id="progressBar" style={{ transform: `scale(${scroll}, 1)` }} />
                </div>
                {<Menubar />}
                {<Navbar userdetails={userpayload} />}
                <Switch>
                  termsAndConditions
                  <Route path="/conditions" exact component={termsAndConditions} />
                  <Route path="/" exact component={Home} />
                  <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                  <Route path="/refund-policy" exact component={RefundPolicy} />
                  <Route path="/terms-and-conditions" exact component={TermsConditions} />
                  <Route path="/Resume" exact component={ResumeLanding} />
                  <Route path="/membership-plans" exact component={PricePlan} />
                  <Route path="/welcome-candidate" exact component={MenteeLanding} />
                  <Route path="/welcome-expert" exact component={MentorLanding} />
                  <Route path="/welcome-enterprise" exact component={EnterpriseLanding} />
                  <Route path="/about-us" exact component={AboutUs} />
                  <Route path="/contact-us" exact component={ContactPage} />
                  <ProtectedRoute path="/hdfcpayment" exact userdetails={userpayload} component={HDFC_Gateway} auth={isAuth} />
                  {(profileType.Mentee || profileType.Corporate) && <ProtectedRoute path="/payment_page" exact userdetails={userpayload} component={HDFC_Gateway} auth={isAuth} />}
                  {profileType.Admin && <ProtectedRoute path="/karmminadmin" exact component={AdminDashboard} auth={isAuth} />}
                  {profileType.Admin && <ProtectedRoute path="/reassign" exact component={Reassign} userdetails={userpayload} auth={isAuth} />}
                  {profileType.Admin && <ProtectedRoute path="/edit-jd" exact component={EditJD} userdetails={userpayload} auth={isAuth} />}
                  {profileType.Admin && <ProtectedRoute path="/matchresume" exact component={matchresume} userdetails={userpayload} auth={isAuth} />}
                  {profileType.Corporate && <ProtectedRoute path="/dashboard" exact userdetails={userpayload} component={Dashboard} auth={isAuth} />}
                  {profileType.Mentor && <ProtectedRoute path="/expert" exact userdetails={userpayload} component={Mentor} auth={isAuth} />}
                  {profileType.Mentee && <ProtectedRoute path="/candidate" exact userdetails={userpayload} component={Mentee} auth={isAuth} />}
                  {profileType.Corporate && <ProtectedRoute path="/enterprise" exact userdetails={userpayload} component={Corporate} auth={isAuth} />}
                  {profileType.Mentee && <ProtectedRoute path="/schedule-an-appointment" exact userdetails={userpayload} component={MakeAppointment} auth={isAuth} />}
                  {profileType.Admin && <ProtectedRoute path="/schedule-an-appointment" exact component={MakeAppointment} auth={isAuth} />}
                  {profileType.Corporate && <ProtectedRoute path="/Resumes-shared" exact userdetails={userpayload} component={ResumeSharedDashboard} auth={isAuth} />}
                  {profileType.Corporate && <ProtectedRoute path="/view/:id/:jdid" exact component={Resume_view} auth={isAuth} />}

                  <ProtectedRoute exact component={UserNotFound} auth={isAuth} />
                </Switch>
                {<Footer />}
              </Route>
              <Route exact path={['/login', '/adminlogin', '/reset', '/socialauth', '/register',
                '/verification', '/resume-builder', '/assessment/:id/:type', '/reciept/:transactionID']}>
                <Switch>
                  <Route path="/login" exact component={Login} />
                  <Route path="/socialauth" exact component={SocialAuth} />
                  <Route path="/register" exact component={Register} />
                  <Route path="/reset" exact component={ResetPassword} />
                  <Route path="/adminlogin" exact component={AdminLogin} />
                  <Route path="/verification" exact component={EmailActivate} />
                  {profileType.Mentee && <ProtectedRoute path="/resume-builder" exact component={ResumeMain} auth={isAuth} />}
                  {profileType.Mentee && <ProtectedRoute path="/assessment/:id/:type" exact component={AssessmentsPage} auth={isAuth} />}
                  {profileType.Mentee && <ProtectedRoute path="/reciept/:transactionID" exact component={MenteeRecieptPage} auth={isAuth} />}
                  <ProtectedRoute exact component={UserNotFound} auth={isAuth} />

                </Switch>
              </Route>
            </Switch>
          </Suspense>
        </Router>
        <SessionTimeout isAuthenticated={isAuth} logOut={handleClick} />
        {/* <CookieConsent
          debug={true}
          location="bottom"
          cookieName="CookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={365}
          enableDeclineButton
          flipButtons={true}
        >This website uses cookies to enhance the user experience.</CookieConsent> */}
      </div></>
  );
}
export default App;