import React, { createContext, useReducer } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import remove from 'lodash/remove';
import demoJsonldData from '../assets/demo/jsonlddata.json';
import { move } from '../utils';
import secureLocalStorage from "react-secure-storage";

var Get_data = []
var view_data = []
var files = secureLocalStorage.getItem('listofResumes')


const initialState = {
  data: {
    jsonld: {
      "@context": [
        "https://jsonldresume.github.io/skill/context.json",
        {
          "gender": {
            "@id": "schema:gender",
            "@type": "@vocab"
          },
          "skill:classOfAward": {
            "@id": "skill:classOfAward",
            "@type": "@vocab"
          },
          "skill:securityClearance": {
            "@id": "skill:securityClearance",
            "@type": "@vocab"
          },
          "category": {
            "@id": "schema:category",
            "@type": "@vocab"
          },
          "dayOfWeek": {
            "@id": "schema:dayOfWeek",
            "@type": "@vocab"
          }
        }
      ],
      '@graph': [
        {
          "@type": "skill:Resume",
        },
        {
          "@type": "Person",
          givenName: [{ '@language': 'en', '@value': '' }],
          familyName: [{ '@language': 'en', '@value': '' }],
          address: []
        }
      ]
    },
    profile: {
      heading: 'Profile',
      photo: '',
      firstName: '',
      lastName: '',
      subtitle: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
      },
      phone: '',
      website: '',
      email: '',
    },
    contacts: {
      enable: true,
      heading: "Contacts"
    },
    address: {
      enable: false,
      heading: 'Address'
    },
    objective: {
      enable: true,
      heading: 'Objective',
      body: '',
    },
    work: {
      enable: false,
      heading: 'Professional Experience',
      items: [],
    },
    projects: {
      enable: true,
      heading: 'Major Projects',
      items: [],
    },
    education: {
      enable: true,
      heading: 'Education',
      items: [],
    },
    awards: {
      enable: false,
      heading: 'Honors & Certifications',
      items: [],
    },
    certifications: {
      enable: true,
      heading: 'Certifications',
      items: [],
    },
    skills: {
      enable: true,
      heading: 'Skills',
      items: [],
    },
    memberships: {
      enable: true,
      heading: 'Memberships',
      items: [],
    },
    languages: {
      enable: true,
      heading: 'Languages',
      items: [],
    },
    references: {
      enable: false,
      heading: 'References',
      items: [],
    },
    extras: {
      enable: false,
      heading: 'Additional',
      items: [],
    },
    // identifier: {
    //   enable: false,
    //   heading: 'Additional',
    //   items: [],
    // },
    hobbies: {
      enable: false,
      heading: 'Hobbies',
      items: [],
    },
  },
  theme: {
    layout: 'Onyx',
    font: {
      family: '',
    },
    colors: {
      background: '#ffffff',
      primary: '#212121',
      accent: '#f44336',
    },
    "layoutblocks": {
      "onyx": [
        [
          "objective",
          "education",
          "work",
          "projects"
        ],
        [
          "skills",
          "awards",
          "extras",
          "languages"
        ],
        [
          "hobbies",
          "references",
        ]
      ],
      "lucario": [
        [
          "objective",
          "education",
          "work",
          "projects"
        ],
        [
          "awards",
          "extras",
          "skills",
          "languages",
          "hobbies"
        ],
        [
          "references",
        ]
      ],
      "pikachu": [
        [
          "skills",
          "languages",
          "hobbies",
          // "certifications"
        ],
        [
          "education",
          "work",
          "projects",
          "awards",
          "extras",
          "references"
        ]
      ],
      "gengar": [
        [
          "objective",
        ],
        [
          "awards",
          "extras",
          "skills",
          // "certifications",
          "languages",
          "hobbies",
        ],
        [
          "education",
          "work",
          "projects",
          "references",
          // "references",
        ]
      ],
      "castform": [
        [
          "skills",
          "awards",
          "extras",
          // "certifications",
          "languages",
          "hobbies"
        ],
        [
          "objective",
          "education",
          "work",
          "projects",
          "references",
          // "extras"
        ]
      ],
      "glalie": [
        [
          "skills",
          "awards",
          "languages",
          // "certifications",
          "hobbies"
        ],
        [
          "objective",
          "education",
          "work",
          "projects",
          "extras",
          "references",
        ]
      ],
      "celebi": [
        [
          "skills",
          "awards",
          // "certifications",
          "languages",
          "hobbies"
        ],
        [
          "objective",
          "education",
          "work",
          "projects",
          "extras",
          "references",
        ]
      ]
    }
  },
  settings: {
    language: 'en',
  },
};

const reducer = (state, { type, payload }) => {
  let items;
  const newState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case 'migrate_section':
      return set({ ...newState }, `data.${payload.key}`, payload.value);
    case 'add_item':
      items = get({ ...newState }, `${payload.key}`, []);
      items.push(payload.value);
      return set({ ...newState }, `${payload.key}`, items);
    case 'custom_add':
      items = get({ ...newState }, `${payload.key}`, []);
      items.push(payload.value);
      return set({ ...newState }, `${payload.key}`, items);
    case 'delete_item':
      items = get({ ...newState }, `${payload.key}`, []);
      remove(items, x => x['@id'] === payload.id);
      return set({ ...newState }, `${payload.key}`, items);
    case 'move_item_up':
      items = get({ ...newState }, `${payload.key}`, []);
      move(items, payload.value, -1);
      return set({ ...newState }, `${payload.key}`, items);
    case 'move_item_down':
      items = get({ ...newState }, `${payload.key}`, []);
      move(items, payload.value, 1);
      return set({ ...newState }, `${payload.key}`, items);
    case 'on_input':
      return set({ ...newState }, payload.key, payload.value);
    case 'save_data':
      secureLocalStorage.setItem('state', JSON.stringify(newState));
      return newState;
    case 'import_data':
      if (payload === null) return initialState;

      for (const section of Object.keys(initialState.data)) {
        if (!(section in payload.data)) {
          payload.data[section] = initialState.data[section];
        }
      }

      return {
        ...newState,
        ...payload,
      };
    case 'load_demo_data':
      return {
        ...newState,
        ...demoJsonldData,
      };
    case 'load_uploaded_data':
      var uploadJsonldData = JSON.parse(secureLocalStorage.getItem('state'))
      return {
        // ...newState,
        ...uploadJsonldData,
      };
    case 'load_view_data':

      return {
        ...newState,
        ...JSON.parse(secureLocalStorage.getItem('state')),
      };

    case 'load_saved_data':


      return {
        ...newState,
        ...JSON.parse(secureLocalStorage.getItem('state')),
        ...Get_data = []
      }



    case 'reset':
      return initialState;
    default:
      return newState;
  }
};

var selectedview = ''



// async function view_api(url) {
//   // Storing response
//   const response = await fetch(url);

//   // Storing data in form of JSON
//   var data = await response.json();

//     if (data[0].JsonLD_Data != undefined) {
//       view_data = data[0].JsonLD_Data
//       secureLocalStorage.setItem('state', data[0].JsonLD_Data)
//       dispatch({ type: 'load_view_data' });
//       dispatch({ type: 'save_data' });
//     }
//     else{
//     }


// }

// const dataaccess =(props) =>{
//   var url = process.env.REACT_APP_API + '/ResumeBuilder/getResume/'+props
//   view_api(url)
//  selectedview =props
// } 
const AppContext = createContext(initialState);
const { Provider } = AppContext;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const AppProvider = StateProvider;
export const AppConsumer = AppContext.Consumer;
// export const data_access = (props) =>dataaccess(props)
export default AppContext;
